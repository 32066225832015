import { JwtUser } from 'types/next-auth'

export const isHentai = () => {
  const value = process.env.NEXT_PUBLIC_ENV_IS_HENTAI
  return value === 'undefined' ? undefined : value === 'true'
}

export const isSafe = () => {
  const value = process.env.NEXT_PUBLIC_ENV_HENTAI_IS_SAFE
  return value === 'undefined' ? undefined : value === 'true'
}

export const isRanobe = () =>
  process.env.NEXT_PUBLIC_ENV_CATEGORY_PROJECT === 'ranobe'

export const isMangaOnline = () =>
  process.env.NEXT_PUBLIC_ENV_APP_NAME === 'MangaOnline'

export const isYaoi = () => {
  const value = process.env.NEXT_PUBLIC_ENV_IS_YAOI
  return value === 'undefined' ? undefined : value === 'true'
}

export const isMaintenance = () =>
  process.env.NEXT_PUBLIC_ENV_IS_MAINTENANCE === 'true'

export const isPaymentFobbiden = () =>
  ['мангахентай.рф','яойманга.рф'].includes(process.env.NEXT_PUBLIC_ENV_SITE_NAME)

export const sleep = async (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms))

export const isMobile = (header: string): boolean =>
  /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(
    header,
  )

export const isAdvAvailable = () =>
  [
    'http://localhost:3000',
    'https://hotmanga.me',
    'https://xn--80aaalhzvfe9b4a.xn--80asehdb',
  ].includes(process.env.NEXT_PUBLIC_ENV_DOMAIN)

export const isAdvDisplay = (user?: JwtUser) =>
  isAdvAvailable() && !user?.activeSubscription
